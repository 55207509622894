/**
 * Получить CSRF токен
 * @returns {string|null}
 */
function getCSRFToken() {
  const token = document.querySelector('meta[name="peshkariki-token"]')

  if (!token) return null
  return token.content
}

export default getCSRFToken
