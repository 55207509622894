<template>
  <ModalUi
    @closeModal="updateModalData({ key: 'isRegModalOpened', value: false })"
    id="reg"
    class="modal"
  >
    <div class="modal-block">
      <i
        class="close"
        @click="updateModalData({ key: 'isRegModalOpened', value: false })"
        @keypress="updateModalData({ key: 'isRegModalOpened', value: false })"
      >
        <img src="/images/newMain/remove-blue.svg" alt="remove" />
      </i>
      <h3 class="title">Регистрация</h3>

      <form>
        <RegistrationTypes
          @setRegistrationButton="setRegistrationButton"
          :registrationTypes="registrationButtons"
          :choosedRegistrationTypeId="chosenRegistrationButton.id"
          :isTypeError="$v.chosenRegistrationButton.$dirty && !$v.chosenRegistrationButton.required"
        />

        <NameInput
          @blur="$v.registrationFio.$touch"
          @update:registrationFio="registrationFio = $event"
          :registrationFio="registrationFio"
          :isMaxError="$v.registrationFio.$dirty && !$v.registrationFio.maxLength"
          :requiredError="$v.registrationFio.$dirty && !$v.registrationFio.required"
        />

        <PasswordInput
          @update:registrationPassword="registrationPassword = $event"
          @blur="$v.registrationPassword.$touch"
          :registrationPassword="registrationPassword.toString()"
          :isMinError="
            $v.registrationPassword.$dirty &&
            (!$v.registrationPassword.minLength || !$v.registrationPassword.required)
          "
        />

        <EmailInput
          v-if="isRequiredEmail"
          @update:registrationEmail="registrationEmail = $event"
          @blur="$v.registrationEmail.$touch"
          :registrationEmail="registrationEmail"
          :isError="$v.registrationEmail.$error"
          class-name="modal-input"
        />

        <PhoneInput
          @blur="$v.registrationPhone.$touch"
          @update:registrationPhone="registrationPhone = $event"
          @confirmNumber="callConfirmActions"
          :isConfirmCodeLoading="isConfirmCodeLoading"
          :registrationPhone="registrationPhone"
          :isIncorrectPhoneNumberError="$v.registrationPhone.$anyError"
          :isConfirmBtnDisabled="isConfirmBtnDisabled"
          :isConfirmCodeAsked="isConfirmCodeAsked"
          :timeLeftConfirmButton="timeLeft"
          :confirmCodeAskedMessage="confirmCodeAskedMessage"
        />

        <transition name="fade" tag="div" mode="out-in">
          <ConfirmCodeInput
            v-if="isConfirmCodeAsked"
            @update:confirmCode="confirmCode = $event"
            @input="checkConfirmCode"
            :confirmCode="confirmCode"
            :isConfirmCodeNumberLoading="isConfirmCodeNumberLoading"
            :confirmPhoneMessage="confirmPhoneMessage"
            :isPhoneConfirmed="isPhoneConfirmed"
            :placeholder="codePlaceholder"
          />
        </transition>

        <transition name="fade" tag="div" mode="out-in">
          <Promocode v-if="isShowPromo" />
        </transition>

        <div v-if="!isPromoFieldShowed && isCustomer" class="promocode">
          <a @click.prevent="setPromoFieldState(true)" @keypress="setPromoFieldState(true)">
            <i> У вас есть промокод? </i></a
          >
        </div>

        <LicenseBlock />

        <div class="modal-buttons">
          <button
            class="send btn-single"
            type="submit"
            :disabled="!isPhoneConfirmed || $v.$invalid"
            @click.prevent="registerHandler"
          >
            <span v-if="!isLoading">Регистрация</span>
            <Spinner v-else />
          </button>
        </div>

        <div
          v-if="registrationResponseMessage"
          class="final-response-message"
          :class="isUserRegistered ? 'success-feedback' : 'invalid-feedback'"
        >
          {{ registrationResponseMessage }}
        </div>
      </form>
    </div>
  </ModalUi>
</template>

<script>
import phoneValidations from '@mixins/phoneValidations'
import registration from '@mixins/registration'
import AuthService from '@services/auth/api'
import ModalUi from '@features/UI/ModalUi.vue'

import { mapState, mapMutations, mapActions } from 'vuex'
import { maxLength, minLength, required, requiredIf } from 'vuelidate/lib/validators'

import Spinner from '@features/UI/FontAwesomeSpinner.vue'
import SynteticRouter from '@utils/synteticRouter'
import Promocode from './RegistrationForm/Promocode.vue'
import NameInput from './RegistrationForm/NameInput.vue'
import RegistrationTypes from './RegistrationForm/RegistrationTypes.vue'
import ConfirmCodeInput from './RegistrationForm/ConfirmCodeInput.vue'
import PhoneInput from './RegistrationForm/PhoneInput.vue'
import PasswordInput from './RegistrationForm/PasswordInput.vue'
import LicenseBlock from './RegistrationForm/LicenseBlock.vue'
import EmailInput from './RegistrationForm/EmailInput.vue'

export default {
  name: 'registration',
  components: {
    Promocode,
    ModalUi,
    RegistrationTypes,
    NameInput,
    ConfirmCodeInput,
    PhoneInput,
    PasswordInput,
    LicenseBlock,
    Spinner,
    EmailInput,
  },

  mixins: [phoneValidations, registration],
  computed: {
    ...mapState([
      'registrationButtons',
      'chosenRegistrationButton',
      'appliedPromocode',
      'tokenGenerator',
      'isPromoFieldShowed',
    ]),
    timeLeft: {
      get() {
        return this.$store.state.timeLeft
      },
      set(value) {
        this.updateModalData({ key: 'timeLeft', value })
      },
    },

    isCustomer() {
      return this.chosenRegistrationButton.id === 2 || this.chosenRegistrationButton.id === 4
    },
    isLegalEntityCusomer() {
      return this.chosenRegistrationButton.id === 4
    },
    isShowPromo() {
      return this.isPromoFieldShowed && this.isCustomer
    },
    isConfirmBtnDisabled() {
      const validations = [
        this.$v.chosenRegistrationButton.$invalid,
        this.$v.registrationEmail.$invalid,
        this.$v.registrationFio.$invalid,
        this.$v.registrationPassword.$invalid,
        this.$v.registrationPhone.$invalid,
      ]

      return (
        this.isConfirmCodeLoading ||
        this.timeLeft > 0 ||
        this.isPhoneConfirmed ||
        validations.some(validator => validator)
      )
    },
  },

  methods: {
    ...mapMutations([
      'setRegistrationButton',
      'setPromoFieldState',
      'updateModalData',
      'resetPromocodeState',
    ]),
    ...mapActions(['removePromocode', 'setCountDown']),
    setTempUser() {
      return AuthService.setTempUser({
        user: {
          phone: this.registrationPhone,
          fio: this.registrationFio,
          pass: this.registrationPassword,
        },
      })
    },
    async getTempUser() {
      try {
        const response = await AuthService.getTempUser()

        if (!response) return

        const { tempUser } = response
        const { tempStatus } = response

        this.registrationFio = tempUser.fio
        this.registrationPhone = tempUser.phone
        this.registrationPassword = tempUser.pass
        this.confirmCodeAskedMessage = tempStatus.message
        this.isConfirmCodeAsked = tempStatus.status
        this.codePlaceholder = tempStatus.messagePlaceHolder
        this.$v.$touch()
      } catch (e) {
        console.error(e.message)
      }
    },
    cleanPhoneDependenciesState() {
      this.isPhoneConfirmed = false
      this.isConfirmCodeAsked = false
      this.confirmCodeAskedMessage = ''
      this.confirmPhoneMessage = ''
      this.timeLeft = 0
      this.confirmCode = ''
    },
    registerHandler() {
      if (typeof window.ym !== 'undefined')
        window.ym(41864514, 'reachGoal', 'confirmed_registration')

      // eslint-disable-next-line no-underscore-dangle
      if (typeof window._tmr !== 'undefined') {
        // eslint-disable-next-line no-undef
        _tmr.push({ type: 'reachGoal', id: 3309779, goal: 'confirmed_registration' })
      }

      this.sendRegisterRequest()
    },
    setRegistrationRoleFromRoute() {
      const registerRoleId = SynteticRouter.params.get()?.['registration-role-id']

      if (!registerRoleId) return

      const registrationButton = this.registrationButtons.find(
        regButton => regButton.id === Number(registerRoleId)
      )

      if (!registrationButton) return

      this.setRegistrationButton(registrationButton)
    },
  },
  created() {
    this.setRegistrationRoleFromRoute()
    this.getTempUser()
  },
  validations() {
    return {
      registrationFio: {
        required: requiredIf(() => this.chosenRegistrationButton.id !== 1),
        maxLength: maxLength(30),
      },
      chosenRegistrationButton: {
        required,
      },
      registrationPhone: {
        required,
        minLength: minLength(4),
        mustBePhoneNumber(val) {
          return this.mustBePhoneNumber(val)
        },
      },
      registrationPassword: {
        required,
        minLength: minLength(6),
      },
      confirmCode: {
        required: requiredIf(() => this.isConfirmCodeAsked),
        reqLength: val => (this.isConfirmCodeAsked ? val.length === 4 : true),
      },
      registrationEmail: {
        required: requiredIf(() => this.isRequiredEmail),
        emailIsValid: val => (this.isRequiredEmail ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val) : true),
      },
    }
  },

  watch: {
    registrationPhone(newVal, oldVal) {
      if (this.isConfirmCodeAsked && oldVal.length > newVal.length) {
        this.cleanPhoneDependenciesState()
      }
    },
    isShowPromo() {
      if (!this.isShowPromo) this.resetPromocodeState()
    },
  },
}
</script>

<style></style>
