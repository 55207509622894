// @ts-check
import toSnakecaseKeys from 'snakecase-keys'
import toCamelcaseKeys from 'camelcase-keys'
import axios from 'axios'
import qs from 'qs'
import validateResponse from '@helpers/responseHandlers'
import './axiosDefaults'

/**
 * @typedef IClientConfig
 * @type {Object}
 * @property {Function} [response] - cb transform response data
 * @property {Function} [request] - cb transform request data
 * @property {Function} [serializer] - cb serializer for get params
 * @property {ConfigParams} [params] - object params for callbacks
 * @property {import('axios').CreateAxiosDefaults} [axiosConfig] - object params for callbacks
 *
 * @typedef ConfigParams
 * @type {Object}
 * @property {Boolean} [useQsForRequest]
 * @property {string} [requestFallbackErrorMsg]
 * @property {qs.IStringifyOptions} [qsParams]
 */

/**
 * @param {unknown} data
 * @returns {unknown}
 */
const transformResponseHandler = data => {
  if (!data) return data

  return typeof data === 'object' ? toCamelcaseKeys(data, { deep: true }) : data
}

/**
 *
 * @param {ConfigParams} params
 * @returns {(arg0: unknown) => unknown}
 */
const transformRequestHandler = params => data => {
  if (!data) return data

  if (data instanceof FormData) return data

  if (typeof data === 'object' && params.useQsForRequest) {
    return qs.stringify(toSnakecaseKeys(data, { deep: true }), params.qsParams)
  }

  if (typeof data === 'object') {
    return toSnakecaseKeys(data, { deep: true })
  }

  return data
}

/**
 *
 * @param {qs.IStringifyOptions} [qsConfig]
 * @returns {(getParams: {}) => string} cb serializer
 */
const paramsSerializer = qsConfig => getParams => {
  const snakeCase = toSnakecaseKeys(getParams)

  return qs.stringify(snakeCase, qsConfig)
}

/**
 * @type {ConfigParams}
 */
const DEFAULT_PARAMS = {
  useQsForRequest: false,
  qsParams: { arrayFormat: 'brackets' },
  requestFallbackErrorMsg: 'Ошибка. Нет сообщения с бэкенда.',
}

/**
 * @implements IClientConfig
 */
class ClientConfig {
  response = transformResponseHandler

  params = DEFAULT_PARAMS

  request = transformRequestHandler(this.params)

  serializer = paramsSerializer(this.params.qsParams)

  axiosConfig = {}

  /**
   *
   * @param {IClientConfig} config
   */
  constructor(config) {
    Object.keys(config).forEach(key => {
      if (typeof this[key] === 'object') {
        Object.assign(this[key], config[key])
      } else {
        this[key] = config[key]
      }
    })
  }
}

/**
 * @param {IClientConfig} [config]
 * @return {import('axios').AxiosInstance} axios instance
 */
const getAxiosClient = (config = {}) => {
  const clientConfig = new ClientConfig(config)

  const axiosClient = axios.create({
    ...clientConfig.axiosConfig,
    // @ts-ignore
    transformResponse: [...axios.defaults.transformResponse, clientConfig.response],

    // @ts-ignore
    transformRequest: [clientConfig.request, ...axios.defaults.transformRequest],

    paramsSerializer: {
      serialize: clientConfig.serializer,
    },
  })

  axiosClient.interceptors.response.use(
    response => {
      const error = validateResponse(response, clientConfig.params.requestFallbackErrorMsg, false)

      if (error) {
        return Promise.reject(error)
      }

      return response
    },
    error => Promise.reject(error.response.data || error)
  )

  return axiosClient
}

export default getAxiosClient
