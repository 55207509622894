<template>
  <div class="validation-wrapper">
    <PasswordInput
      autocomplete="new-password"
      v-model="password"
      class-name="modal-input"
      @blur="$emit('blur')"
      placeholder="Придумайте пароль"
    />
    <transition name="fade" tag="div" mode="out-in">
      <div v-if="isMinError" class="invalid-feedback">Минимальная длина пароля - 6 символов</div>
    </transition>
  </div>
</template>

<script>
import PasswordInput from '@features/PasswordInput.vue'

export default {
  components: { PasswordInput },
  props: {
    registrationPassword: {
      type: String,
      required: true,
    },
    isMinError: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    password: {
      get() {
        return this.registrationPassword
      },
      set(val) {
        this.$emit('update:registrationPassword', val)
      },
    },
  },
}
</script>
