import AppHorizontalLoader from '@components/AppHorizontalLoader.vue'
import ErrorMessage from '@components/ErrorMessage.vue'
import { defineAsyncComponent } from 'vue'

// ожидает импорт вида () => import('./components/AppStyles.vue' /* webpackChunkName: "AppStyles" */)
const getAsyncComponent = (loader, loadingComponent, errorComponent) =>
  defineAsyncComponent({
    loader,
    loadingComponent:
      typeof loadingComponent !== 'undefined' ? loadingComponent : AppHorizontalLoader,
    delay: 200,
    errorComponent: typeof errorComponent !== 'undefined' ? errorComponent : ErrorMessage,
    timeout: 15000,
  })

export default getAsyncComponent
