const useMaskPhone = rawData => {
  const handledPhone = rawData.toString().replace(/\D/g, '').slice(-10)

  if (handledPhone.length === 10)
    return handledPhone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})/, '($1)$2-$3-$4')
  if (handledPhone.length > 8)
    return handledPhone.replace(/^(\d{3})(\d{3})(\d{2})(\d{0,2})(\d)*/, '($1)$2-$3-$4')
  if (handledPhone.length > 6)
    return handledPhone.replace(/^(\d{3})(\d{3})(\d{0,2})(\d)*/, '($1)$2-$3')
  if (handledPhone.length > 3) return handledPhone.replace(/^(\d{3})(\d{0,3})/, '($1)$2')
  return handledPhone.replace(/^(\d*)/, '($1')
}

export default useMaskPhone
