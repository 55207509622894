import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const promocodeState = {
  promocode: '',
  appliedPromocode: '',
}

const registrationButtons = [
  {
    name: 'Заказчик<br>физ.лицо',
    id: 2,
  },
  {
    name: 'Заказчик<br>юр.лицо',
    id: 4,
  },
  {
    name: 'Курьер',
    id: 1,
  },
]

export default new Vuex.Store({
  state: {
    registrationButtons: [...registrationButtons],
    chosenRegistrationButton: registrationButtons[0],
    isRegModalOpened: false,
    isAuthModalOpened: false,
    isFeedbackModalOpened: false,
    isRecoveryModalOpened: false,
    isReviewModalOpened: false,
    tokenGenerator: null,
    timeLeft: 0,
    isPromoFieldShowed: false,
    deeplink: null,
    ...promocodeState,
  },
  actions: {
    removePromocode({ state, commit }) {
      if (state.appliedPromocode) {
        commit('updateModalData', {
          key: 'appliedPromocode',
          value: null,
        })
        commit('updateModalData', {
          key: 'promocode',
          value: null,
        })
      }
    },
    setCountDown({ state }) {
      if (state.timeLeft < 1) state.timeLeft = 30

      const timer = setInterval(() => {
        state.timeLeft -= 1

        if (state.timeLeft <= 0) {
          clearInterval(timer)
        }
      }, 1000)
    },
  },
  mutations: {
    setRegistrationButton(state, val) {
      // Триггеры целей для яндекс-метрики
      if (typeof window.ym !== 'undefined') {
        if(val.id === 2) {
          window.ym(41864514,'reachGoal','personal account')
        } else if (val.id === 4) {
          window.ym(41864514,'reachGoal','company account')
        } else if (val.id === 1) {
          window.ym(41864514,'reachGoal','courier account')
        }
      } 
      state.chosenRegistrationButton = val
    },
    setPromoFieldState(state, bool) {
      state.isPromoFieldShowed = bool
    },
    // она вообще нужна???
    setState(state, data) {
      state[data.key] = data.bool
    },
    updateModalData(state, data) {
      state[data.key] = data.value
    },
    setTokenGenerator(state, func) {
      state.tokenGenerator = func
    },
    resetPromocodeState(state) {
      Object.keys(promocodeState).forEach(key => {
        state[key] = promocodeState[key]
      })
    },
  },
})
