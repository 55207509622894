// eslint-disable-next-line no-unused-vars
import { AxiosResponse } from 'axios'

export class ResponseError extends Error {
  errors = null

  /**
   * Кастомный объект ошибки для поля со списком ошибок
   * @param {String} message сообщение с ошибкой
   * @param {Array<{key: string, message: string}> | null} errorsMessagesList список сообщений с ошибками
   */
  constructor(message, errorsMessagesList = null) {
    super(message)

    if (errorsMessagesList) this.errors = errorsMessagesList
  }
}

/**
 * UI обработка ошибок
 * @param {Error, ResponseError} e объект ошибки
 * @param {(msg: string) => void} handler коллбэк, например toast, принимает строку с ошибкой
 * @returns {Error|ResponseError} объект ошибки для возможности дольнейшей обработки
 */
export function handleError(e, handler) {
  if (!handler) return e

  if (e instanceof ResponseError) {
    if (e.errors) {
      e.errors.forEach(error => {
        handler(error.message)
      })
      return e
    }

    handler(e.message)
  }

  if (e instanceof Error) {
    handler(e.message)
  }

  return e
}

/**
 * Валидация ответа бэкэнда
 * @param {Object} response ответ сервера
 * @param {{success?: boolean, message?: string, errors?: Array<{key: string, message: string}> | null}} response.data
 * @param {String} fallbackErrorMsg сообщение, если запрос завершился неудачей, но нет поля сообщения
 * @param {Boolean} throwError выкидывать ли исключение
 */
const validateResponse = (
  response,
  fallbackErrorMsg = 'Ошибка. Нет сообщения с бэкенда.',
  throwError = true
) => {
  if (typeof response.data?.success !== 'undefined' && !response.data.success) {
    const err = new ResponseError(response.data?.message || fallbackErrorMsg, response.data?.errors)

    if (throwError) throw err

    return err
  }

  return undefined
}

export default validateResponse
