<template>
  <div class="validation-wrapper">
    <input
      type="text"
      name="confirmCode"
      placeholder="Электронная почта"
      v-bind="$attrs"
      :class="className"
      @blur="$emit('blur')"
      v-model="email"
      @input="validateEmail"
    />
    <transition name="fade" tag="div" mode="out-in">
      <div v-if="isError" class="invalid-feedback">Введите корректную почту</div>
    </transition>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    registrationEmail: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      required: true,
    },
    className: {
      type: [String, Array, Object],
      default: '',
    },
  },

  computed: {
    email: {
      get() {
        return this.registrationEmail
      },
      set(val) {
        this.$emit('update:registrationEmail', val)
      },
    },
  },
  methods: {
    validateEmail(e) {
      this.$nextTick(() => {
        this.email = e.target.value.replace(/[^@.\w-]/gi, '')
      })
    },
  },
}
</script>
