import getQueryStringParameter from './getQueryStringParametr'

const validateArgument = (arg, errorMsg = '') => {
  if (typeof errorMsg !== 'string') throw new Error('Ожидалась строка для соощбения ошибки')
  if (typeof arg !== 'string')
    throw new Error(errorMsg || 'Ожидалась строка для title/path/description')
}

const validateParam = param => {
  if (typeof param !== 'object') throw new Error('Ожидалась объект для query параметра')

  validateArgument(param?.key, 'поле key должно быть строкой')
  if (typeof param.value !== 'string' && param.value !== null)
    throw new Error('value ожидалась строк, либо null')
}

class SynteticRouter {
  static pageDescriptionEl = document.querySelector('meta[name="description"]')

  static path = {
    get() {
      return window.location.href
    },
    /**
     * @param {string} path pathname
     */
    set(path, scrollTo = false) {
      try {
        validateArgument(path, 'некорректны путь')
        window.history.pushState({}, '', path)
        if (scrollTo) window.scrollTo({ top: 0, behavior: 'smooth' })
      } catch (e) {
        console.error(path, e.message)
      }
    },
  }

  static title = {
    get() {
      return document.title
    },
    /**
     * @param {string} path pathname
     */
    set(name) {
      try {
        validateArgument(name, 'некорректное название страницы')
        document.title = name
      } catch (e) {
        console.error(name, e.message)
      }
    },
  }

  static description = {
    get() {
      return SynteticRouter.pageDescriptionEl.content
    },
    /**
     * @param {string} text description text
     */
    set(text) {
      try {
        validateArgument(text, 'некорректное описание страницы')
        SynteticRouter.pageDescriptionEl.content = text
      } catch (e) {
        console.error(text, e.message)
      }
    },
  }

  static params = {
    get() {
      const params = new URLSearchParams(window.location.search)
      const paramsObj = {}

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of params.entries()) {
        paramsObj[key] = value
      }

      return paramsObj
    },
    /**
     * @param {Object} queryObj
     * @param {string} queryObj.key
     * @param {string} queryObj.value
     */
    set(queryObj) {
      if (queryObj === null) SynteticRouter.path.set(window.location.pathname)

      try {
        validateParam(queryObj)

        const query = getQueryStringParameter(window.location.search, queryObj.key, queryObj.value)

        SynteticRouter.path.set(`${window.location.pathname}${query}`)
      } catch (e) {
        console.error(queryObj, e.message)
      }
    },
  }
}
window.router = SynteticRouter
export default SynteticRouter
