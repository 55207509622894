<template>
  <div class="modal-forms">
    <transition-group name="fade" tag="div" mode="out-in">
      <Registration key="reg" v-if="isRegModal" />
      <Authorization key="auth" v-if="isAuthModal" />
      <Feedback key="feedback" v-if="isFeedbackModal" />
      <Recovery key="recovery" v-if="isRecoveryModal" />
      <Review key="review" v-if="isReviewModal" />
    </transition-group>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import getAsyncComponent from '@helpers/asyncComp'
import grecaptcha from '@mixins/grecaptcha'
import { AUTH_QUERY_ACTION, FEEDBACK_QUERY_ACTION, REG_QUERY_ACTION } from '@constants/queryActions'
import useRouteAction from '@hooks/useRouteAction'
import Authorization from './components/Authorization.vue'
import Registration from './components/Registration.vue'

export default {
  name: 'ModalForms',

  components: {
    Recovery: getAsyncComponent(() => import('./components/Recovery.vue'), null),
    Feedback: getAsyncComponent(() => import('./components/Feedback.vue'), null),
    Registration,
    Authorization,
    Review: getAsyncComponent(() => import('./components/Review.vue'), null),
  },

  mixins: [grecaptcha],

  computed: {
    isAuthModal: {
      get() {
        return this.$store.state.isAuthModalOpened
      },
      set(value) {
        this.updateModalData({ key: 'isAuthModalOpened', value })
      },
    },
    isRegModal: {
      get() {
        return this.$store.state.isRegModalOpened
      },
      set(value) {
        this.updateModalData({ key: 'isRegModalOpened', value })
      },
    },
    isFeedbackModal: {
      get() {
        return this.$store.state.isFeedbackModalOpened
      },
      set(value) {
        this.updateModalData({ key: 'isFeedbackModalOpened', value })
      },
    },
    isRecoveryModal: {
      get() {
        return this.$store.state.isRecoveryModalOpened
      },
      set(value) {
        this.updateModalData({ key: 'isRecoveryModalOpened', value })
      },
    },
    isReviewModal: {
      get() {
        return this.$store.state.isReviewModalOpened
      },
      set(value) {
        this.updateModalData({ key: 'isReviewModalOpened', value })
      },
    },
  },

  methods: {
    ...mapMutations(['setTokenGenerator', 'updateModalData']),

    checkRouteActions({ action, deeplink }) {
      this.checkModalAction(action)
      this.checkDeeplink(deeplink)
    },
    checkModalAction(action) {
      switch (action) {
        case AUTH_QUERY_ACTION: {
          this.isAuthModal = true
          break
        }

        case REG_QUERY_ACTION: {
          this.isRegModal = true
          break
        }

        case FEEDBACK_QUERY_ACTION: {
          this.isFeedbackModal = true
          break
        }

        default: {
          break
        }
      }
    },
    checkDeeplink(deeplink) {
      if (deeplink) this.updateModalData({ key: 'deeplink', value: deeplink })
    },
  },

  created() {
    useRouteAction(this.checkRouteActions)

    this.setTokenGenerator(this.tokenGenerator)
  },
}
</script>

<style lang="scss">
@import '@root/css/re-design_src/component_style/modals';
</style>
